<template>
  <v-col cols="12">
    
    <v-row style="padding-bottom:50px;" 
            class="dashboard main-col-container"
            v-if="ready">
      <v-col cols="11" sm="12" md="12" lg="12" class="mx-auto header-section text-center px-0 mt-0 mb-5">
          <v-img src="../../assets/img/monnaie_pirate_logo.png" class="d-none d-sm-inline-flex" width="180"/>
          <v-img src="../../assets/img/monnaie_pirate_logo.png" class="d-inline-flex d-sm-none" width="150"/>
          <h1 class="font-main pb-1 pt-0 mb-5 pb-5 yellow--text text--darken-2">
            <small>La monnaie pirate</small>
          </h1>
      </v-col>
  
      <!-- <v-col cols="11" sm="11" md="8" lg="7" class="mx-auto pb-0 d-none d-md-flex" 
             style="border-top:1px dashed #fbc02d;margin-top:10px;"></v-col> -->
  
      <v-col cols="11" sm="9" md="7" lg="4" class="mx-auto pt-0">
        <v-card outlined>
          <v-card-title>
              <v-icon  class="mb-2">mdi-cellphone-arrow-down</v-icon>
              Installer l'application <span class="d-none d-sm-none">Android</span>
            </v-card-title>
          <v-card-subtitle class="ml-1">
              <v-icon small class="mb-1">mdi-download</v-icon>
              Télécharger le fichier .APK, puis exécutez-le pour installer l'application.
            </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text class="font-papyrus pl-2">
            <!-- Cliquez sur le lien suivant et suivez les instructions d'installation.
            <br><br> -->
            <v-icon class="ml-2">mdi-information-outline</v-icon> <b>Bon à savoir :</b>
            <ul>
              <li class="pt-2">Notre application Android n'est pas disponible sur le GooglePlay Store.
                  C'est une application indépendante, qui souhaite exister sans l'aide de Google, afin de protéger votre identité et vos données personnelles.
              </li>
              <li class="pt-2">Pour les mêmes raisons, notre application Android n'est pas signée par un certificat Google.
                Ce qui signifie que votre smarthpone Android va peut-être vous alerter de son éventuelle "dangerosité".
              </li>
              <li class="pt-2">
                Mais rassurez-vous amis pirates ! En réalité, vous êtes en parfaite sécurité ! Loin des regards indiscrets de Google...
                Vous pouvez installer notre application sans crainte, et garder votre Monnaie Pirate à portée de main, dans votre poche !
              </li>
            </ul>
            <v-btn x-large class="mt-5" block style="font-size:16px !important;" 
                    color="blue" target="blank" :href="linkInstallApp">
              <v-icon class="mb-0 mr-1">mdi-download</v-icon>
              Télécharger l'app
            </v-btn>
          </v-card-text>
          
        </v-card>
      </v-col>
    </v-row>
  
  </v-col>
  </template>
  
  <style>
    
  </style>
  
  <script>
  
  import axios from 'axios'
  import router from '../../router/router'
  //import core from '../plugins/core.js'
  
  //import Post from '@/components/Post.vue'
  const config = require('../../config/' + process.env.NODE_ENV)
  
  
  export default {
    name: 'dashboard',
    components: {  },
    data: () => ({
      ready: false,
      linkInstallApp: null
    }),
    async mounted(){
      if(this.isTheApp) {
        router.push("/update-app-android")
        return
      }
      axios.get("/data/apk-infos").then((res)=>{
        this.linkInstallApp = res.data.appAndroidUrl
        this.ready = true
        //console.log("ready", this.ready)
      })
    },
    methods: {
     
    },
    computed: {
      isTheApp(){ return config.appAndroidVersion != null},
      isAndroid(){
        return navigator.userAgent.match(/Android/i)
      }
      //urlDownload() { return config.root_node.url + '/app/' + config.mobileAppName + ".v." + config.mobileAppVersion + ".apk" }
    }
  }
  </script>
  